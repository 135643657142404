const ptBRJSON = {
  "Sign out": "Logout",
  "Sign in": "Login",
  "Fill in the fields below to sign into your account.":
    "Preencha os dados abaixo para realizar o Login",
  "Don’t have an account, yet?": "Não tem uma conta?",
  "Sign up here": "Crie aqui sua conta.",
  "The email provided should be a valid email address":
    "O email fornecido precisa se validado.",
  "The email field is required": "O campo email é requerido",
  "The password field is required": "O campo password é requerido",
  "You must agree to our terms and conditions":
    "Você deve aceitar os termos e condições",
  "I accept the": "Eu li e aceito os",
  "terms and conditions": "termos e condições",
  "Lost password?": "Esqueceu o password?",
  "Email address": "Endereço de Email",
  Password: "Password",
  "Create your account": "Criar a sua conta",
  Name: "Nome",
  "Already have an account?": "Você já tem uma conta?",
  "Sign in here": "Realize o Login aqui",
  "Create account": "Crie sua conta",
  "Fill in the fields below to sign up for an account.":
    "Preencha os campos abaixo para criar sua conta",
  "Want to try to sign in again?": "Quero tentar logar novamente",
  "Click here": "Clique aqui",
  "Enter the email used for registration to reset your password.":
    "Informe o email usado no processo de registro, para resetar seu password.",
  "Send me a new password": "Me envie um novo password",
  "The password reset instructions have been sent to your email":
    "Caso esteja cadastrado, um email com as instruções será enviado.",
  "Check your email for further instructions": "Verifique seu email",
  "Continue to login": "Proceda para o login",
  "Wrong credentials or incomplete prerequisites.":
    "Credenciais incorretas ou Pré-requisitos incompletos.",
  Learning: "Cursos",
  Challenges: "Desafios",
  "learning points earned": "Pontos de Aprendizado",
  Courses: "Cursos",
  "Use this page to manage your products , the fast and easy way.":
    "Afie seu conhecimento com os cursos abaixo.",
  "We couldn't find any products matching your search criteria":
    "Não foi possível encontrar cursos com seus critérios de pesquisa",
  "Product name": "Nome do Curso",
  Price: "Preço",
  Students: "Estudantes",
  Categories: "Categorias",
  "Product Details": "Detalhes Curso",
  "Challenge yourself and learn.": "Desafie-se e acelere seu aprendizado",
  "Search by course name...": "Pesquise pelo nome do curso...",
  "Search by challenge name...": "Pesquise pelo nome do desafio...",
  Plataform: "Plataforma",
  "Extreme Hacking": "Extreme Hacking",

  Explore: "Explore a Plataforma",
  "Dashboard Menu Header": "Menu rápido para os conteúdos",
  "Language Switcher": "Escolha o Idioma",
  "Personal Details": "Detalhes Pessoais",
  "Manage informations related to your personal details":
    "Gerencie suas informações",
  Edit: "Editar",
  "Change your security preferences below":
    "Altere suas configurações de segurança",
  Security: "Segurança",
  "Change Password": "Altere seu password",
  "You can change your password here": "Aqui você pode alterar seu password",
  "Please access your email account to validate!":
    "Por favor, acesse seu email e clique no link ou copie-o no seu browser para validar sua conta no ExtremeHacking.",
  "Email Sent": "Email de Validação Enviado",
  "Already Validated your email?": "Já validou seu conta?",
  FR: "FREE",
  PD: "PRO",
  CO: "COMPANY",
  CL: "ALUNOS",
  SP: "SPECIAL",
  Class: "Alunos",
  "Construindo: ": "Construindo",
  "Change your password.": "Informe seu novo password",
  "Password changed with success": "Password trocado com sucesso.",
  "Sending Answer...": "Enviando a resposta...",
  "Thanks for your review!": "Obrigado pela sua avaliação!",
  "Verified Reviewer": "Avaliador Verificado",
  Enroll: "Matricule-se",
  "Leave your review about the Challenge":
    "Deixe sua avaliação sobre o Desafio",
  "I accept this Challenge": "Eu aceito este Desafio",
  "Oops, an error occurred!": "Oops, um erro ocorreu!",
  "Search Courses or Challenges": "Pesquise por Cursos ou Desafios",
  "Profile for": "Perfil do",
  "Signup the CTF": "Inscreva-se no CTF",
  "Select your team": "Selecione seu time",
  "Only Captains can signup the team":
    "Somente Capitães podem inscrever o time",
  "Bellow options are the teams that you are captain":
    "Abaixo estão os times em que você é capitão",
  "Registered at the event with the team": "Inscrito no evento com a equipe",
  Challenge: "Desafio",
  "Find the plan that suits you best":
    "Encontre o plano que mais se adequa a você",
  "Choose your plan": "Escolha seu plano",
  month: "mês",
  year: "ano",
  Features: "Recursos",
  Monthly: "Mensal",
  Annual: "Anual",
  "Assinando Agora você ganha 20% de desconto no primeiro mês":
    "Assinando Agora você ganha 20% de desconto no primeiro mês",
  "Select Plan": "Eu quero esse",
  "Thank you for your purchase!": "Obrigado por sua compra!",
  "You will receive a confirmation email shortly!":
    "Você receberá um email de confirmação em breve.",
  "Your request has been sent to the team captain":
    "Sua solicitação foi enviada ao capitão do time",
  "Additional Informations": "Informações Adicionais",
  Reviews: "Avaliações",
  "Challenge Details": "Detalhes do Desafio",
  "total challenges": "total de desafios",
  "Generate VPN Profile": "Gerar Perfil VPN",
  "VPN Server": "Servidor VPN",
  "Download here to connect to the VPN":
    "Realize aqui o download do perfil para conectar-se a VPN",

  "Courses Enrolled Completed": "Cursos Completados",
  Points: "Pontos",
  "Points to ": "Pontos para ",
  "Challenges Accepted Completed": "Desafios Completados",
  Achieved: "Conquistados",
  "First Blood Achieved":
    "Número de vezes que você foi o primeiro a conseguir resolver um desafio ou quiz",
  "Course points": "Pontos de Curso",
  "Challenges points": "Pontos de Desafio",
  "My Ranking": "Classificação",
  Leaderboard: "Top 10",
  Ranking: "Rank",
  Member: "Participante",
  "Points to reach the next level": "Pontos para alcançar o próximo nível",
  "Percentual of courses completed": "Percentual de cursos completados",
  "Percentual of challenges completed": "Percentual de desafios completados",
  "Total points earned from courses and challenges":
    "Total de pontos ganhos com cursos e desafios",
  "Total points earned from courses": "Total de pontos ganhos com cursos",
  "Total points earned from challenges": "Total de pontos ganhos com desafios",
  "Find your challenge, and learn something new!":
    "Encontre seu desafio e aprenda algo novo!",
  level: "Nível",
  Level: "Nível",
  title: "Título",
  "Sort by...": "Ordenar por...",
  Showing: "Mostrando",
  of: "de",
  challenges: "desafios",
  "Unlock Your Potential": "Resolver Agora",
  "Rows per page": "Elementos por página",
  "Search Challenges": "Pesquisar Desafios",
  Instruction: "Instrução",
  Verifying: "Verificando",
  Running: "Rodando",
  Stopped: "Parado",
  "Pronto Para Iniciar": "Pronto Para Iniciar",
  "Parando...": "Parando...",
  "Inicializando...": "Inicializando...",
  "Realize o Download do Arquivo para encontrar a Flag":
    "Realize o Download do Arquivo para encontrar a Flag",
  "Students Reviews": "Avaliações",
  Submit: "Enviar",
  "I accept this Challenge": "Aceite o Desafio e Supere seus Limites!",
  "Access to Lab here": "Acesse o Lab aqui",
  Expires: "Termina em",
  "My Learning": "Meu Aprendizado",
  "My Courses and Challenges!": "Meus Cursos e Desafios!",
  "Matricule-se no curso.": "Matricule-se no curso.",
  "Conteúdo do curso": "Conteúdo do curso",
  Outcomes: "Resultados",
  Content: "Conteúdo",
  "Course Details": "Detalhes do Curso",
  Hint: "Dica",
  "The tips come at a cost.": "As dicas tem um custo.",
  "Não quero a dica": "Não quero a dica",
  "I Accept": "Eu Aceito",
  "Congratulations! Correct answer": "Parabéns! Resposta correta",
  "Your download is ready!": "Seu download está pronto!",
  "Congratulations! You have completed the last module of the course.":
    "Parabéns! Você concluiu o último módulo do curso.",
  "Leave your review about the Course.": "Deixe sua avaliação sobre o Curso.",
  courses: "Cursos",
  "Search Courses": "Pesquisar Cursos",
  "Find your Course": "Encontre seu Curso",
  "Start to Learn Now!": "Comece a Aprender Agora!",
  "CTF Events": "Eventos CTF",
  "'Let's having fun!'": "Vamos nos divertir!",
  Thanks: "Obrigado",
  "Search CTF": "Pesquisar CTF",
  "total Events": "total de eventos",
  "Inscreva-se": "Inscreva-se",
  "This event has finished": "Este evento já terminou",
  "Thanks you for participating": "Obrigado por participar!",
  "Remaining Time": "Tempo Restante",
  Prizes: "Prêmios",
  Audience: "Público",
  Organization: "Organizadora",
  "Visit Website": "Visite o Website",
  Sponsor: "Patrocinador",
  "Signup the CTF": "Inscreva-se no CTF",
  "Organizated by": "Organizado por",
  "Only Captains can signup the team":
    "Somente Capitães podem inscrever o time",
  "Bellow options are the teams that you are captain":
    "Abaixo estão os times em que você é capitão",
  Cancel: "Cancelar",
  Save: "Salvar",
  "Back to CTF Events": "Voltar para Eventos CTF",
  Teams: "Times",
  "CTF Teams": "Equipes CTF",
  "Create Team": "Criar Equipe",
  "Fill in the fields below to create a Team":
    "Preencha os campos abaixo para criar um time",
  "The name field is required": "O campo nome é obrigatório",
  "The lema name field is required": "O campo lema é obrigatório",
  "The Team has been removed": "O time foi removido",
  "Erro to remove Team": "Erro ao remover o time",
  "Your request has been sent to the team captain":
    "Sua solicitação foi enviada ao capitão do time",
  "We couldn't find any teams matching your search criteria":
    "Não encontramos nenhum time que corresponda aos seus critérios de pesquisa",
  Name: "Nome",
  Captain: "Capitão",
  Players: "Jogadores",
  "Ask to Join": "Solicitar Entrada",
  "Are you sure you want to permanently delete this Team": "Você tem certeza?",
  Delete: "Deletar",
  "User - Profile": "Perfil do Usuário",
  "Back to Teams": "Voltar para Times",
  Details: "Detalhes",
  Role: "Função",
  "Join Requests": "Solicitações de Entrada",
  Settings: "Configurações",
  "Manage informations related to your Team":
    "Gerencie informações relacionadas ao seu Time",
  "Team Settings": "Configurações do Time",
  "Any Join Request found": "Nenhuma solicitação de ingresso encontrada",
  Profile: "Perfil",
  "Edit Profile": "Editar Perfil",
  "Access Logs": "Logs de Acesso",
  "Recent sign in activity logs": "Logs de atividade de login recentes",
  "I don't want the tip": "Não quero a dica",
  "Enjoy your tip": "Aproveite sua dica",
  True: "Verdadeiro",
  False: "Falso",
  "Execute the task and click Complete":
    "Execute a tarefa e clique em Concluir",
  Complete: "Concluir",
  Theme: "Tema",
  "Profile settings": "Config. Perfil",
  "PowerOn/PowerOff": "Ligar/Desligar",
  'Revert': 'Reverter',
  "Challenge IP": "IP do Desafio",
  "Access ExtremeBox here": "Acesse a ExtremeBox aqui",
  "Em Breve": "Em Breve",
  "Ranking_board": "Classificação",
  "Team": "Equipe",
  "Teams": "Equipes",
  "The tips come at a cost.": "As dicas tem um custo.",
  "This tip will cost": "Esta dica custará",
  "I don't want the tip": "Não quero a dica",
  "Enjoy your tip": "Aproveite sua dica",
  "This tip will cost": "Esta dica custará",
  "Event has ended.": "Evento Finalizado.",
  "Register": "Inscrever-se",
  "Regras": "Regras",
  "User": "Usuário",
  "Registered at the event with": "Inscrito no evento",
  "Start Date": "Data de Início",
  "End Date": "Data de Término",
  "Revert the machine to prestine state": "Reverter a máquina para o estado original",
  'Birth date': 'Data de Nascimento',
  'Birth date is required': 'Data de Nascimento é obrigatório',
  'You must be at least 18 years old': 'Você deve ter pelo menos 18 anos',
  "Thank you!": "Obrigado!",
  'Estamos realizando uma validação, assim que estiver tudo ok, você receberá um email.': 'Estamos realizando uma validação, assim que estiver tudo ok, você receberá um email.',
  "You will be redirected to the home page in a few seconds.": "Você será redirecionado para a página inicial em alguns segundos.",
  "As soon as we receive payment confirmation from the Payment Gateway, you will receive an email with the purchase confirmation.": "Assim que recebermos a confirmação de pagamento do Gateway de Pagamento, você receberá um email com a confirmação da compra.",
  "If you have any questions, please contact us at": "Se você tiver alguma dúvida, entre em contato conosco em",
  "Overcame the challenge!": "superou o desafio!",
  "Users have this badge": "Usuários vencedores total",
  "Users earned this badge": "Usuários vencedores",
  "Completed on ": "Concluído em ",
  challenge: "Desafios",
  course: "Cursos",
  lab: "Labs",
  completed: "Concluído",
  "AnotherRunning": "Outra Instância em Execução",
  "subscription": "Assinatura",
  "This Walkthrough will cost 90% of the challenge points.": "Este Walkthrough custará 90% dos pontos do desafio.",
  "The Walkthrough come at a cost." : "O Walkthrough tem um custo.",
  "I don't want the Walkthrough": "Não quero o Walkthrough",
  "Walkthrough Official - Não disponível": "Walkthrough Oficial - Não disponível - Em Breve",
  "Only available to subscribers": "Somente disponível para assinantes",
  "Subscribe" : "Assine Agora",
  "Opss! this is not the correct answer.": "Opss! esta não é a resposta correta.",
  "Percentual de acerto: ": "Percentual de acerto: ",
};

export default ptBRJSON;
