import { createContext, useEffect, useRef, useState } from "react";
const DEV = process.env.REACT_APP_DEV;

export const WebsocketContext = createContext(
  false,
  null,
  () => {},
  false,
  null
);

//ready,message, send
export const WebsocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [rxMessage, setRxMessage] = useState(null);
  const [labRunning, setLabRunning] = useState(false);
  const [labId, setLabId] = useState(false);

  const socketRef = useRef(null);
  const check_connection = useRef(null);

  const labStatus_ref = useRef(false);
  const labIntervalCheck = useRef(null);

  window.addEventListener('beforeunload', () => {
    // Feche a conexão WebSocket antes do refresh
    if (socketRef.current) {
      socketRef.current.close();
    }
  });

  const connectWebSocket = () => {
    const hasSession = localStorage.getItem("session") ? true : false;
    console.log("AUTHPROVIDER", hasSession);
    if (hasSession === true) {
      const session = JSON.parse(localStorage.getItem("session"));
      // console.log("SEssion GET", session);

      let newSocket = undefined;
      if (DEV === "true") {
        newSocket = new WebSocket(
          "ws://" + window.location.hostname + `:9000/ws/lab/?${session}`
        );
      } else {
        newSocket = new WebSocket(
          "wss://" + window.location.hostname + `/ws/lab/?${session}`
        );
      }
      socketRef.current = newSocket;

      newSocket.onopen = function () {
        console.log("WebSocket connection established.");
        setIsConnected(true);
      };

      newSocket.onclose = function (event) {
        // console.log(
          // "WebSocket connection closed with code " + event.code + "."
        // );
        if (event.code === 1011 || event.code === 1006) {
          //localStorage.removeItem("session");
          setIsConnected(false);
        }
        setIsConnected(false);
        setTimeout(function () {
          console.log("WebSocket attempting to reconnect...");
          connectWebSocket();
        }, 5000);
      };

      // onerror 401 Unauthorized remove session
      newSocket.onerror = function (event) {
        // console.log("WebSocket error observed:", event);
        if (event.message === "Unauthorized") {
          console.log("Unauthorized");
          localStorage.removeItem("session");
          setIsConnected(false);
        }
      };

      newSocket.onmessage = (event) => {
        //every receive is a new state
        const parsedData = JSON.parse(event.data);
        parsedData.seq = Math.floor(Math.random() * 10000);
        const dataWithSeq = JSON.stringify(parsedData);

        
        if (parsedData.disconnect === '401 Unauthorized') {
            console.error('Unauthorized access:', parsedData.message);
            console.log("Unauthorized");
            localStorage.removeItem("session");
            setIsConnected(false);
        }
        
        setRxMessage(dataWithSeq);

        const response = JSON.parse(event.data);
        console.log("WebSocket message received:", response);

        if (response.cmd === "Running") {
          if (labStatus_ref.current === false) {
            labStatus_ref.current = true;
            // console.log("Running", response.type);
            if (response.type === "VM" || response.type === "VMC") {
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status", labIntervalCheck.current);
                socketRef.current.send(
                  JSON.stringify({ cmd: "VMStatus", id: response.id })
                );
              }, 20000);
            } else {
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status 98", response.id)
                socketRef.current.send(
                  
                  JSON.stringify({ cmd: "getStatus", id: response.id })
                );
              }, 20000);
            }
            setLabRunning(true);
            setLabId(response.id);
          }
        } else if (response.cmd === "Initializing") {
          if (response.type === "VM" || response.type === "VMC") {
            if (labIntervalCheck.current) {
              clearInterval(labIntervalCheck.current);
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status", labIntervalCheck.current);
                socketRef.current.send(
                  JSON.stringify({ cmd: "VMStatus", id: response.id })
                );
              }, 20000);
            }
          }
        } else if (response.cmd === "Reverting") {
          setLabRunning(false);
          setLabId(response.id);
        } else if (response.cmd === "Stopped") {
          setLabRunning(false);
          setLabId(null);
          labStatus_ref.current = false;
          // console.log("Stopped", labIntervalCheck.current);
          //Clear Interval if exists and set to null
          if (labIntervalCheck.current) {
            clearInterval(labIntervalCheck.current);
            labIntervalCheck.current = null;
          }

          //if (labIntervalCheck.current) {
          //clearInterval(labIntervalCheck.current);

          //labIntervalCheck.current = null;
          //}
        } else if (response.cmd === "Stopping") {
          //setLabStatus("Parando...");
          //setLabRunning(false);
          setLabRunning(false);
          setLabId(null);
          labStatus_ref.current = true;
          if (!labIntervalCheck.current) {
            // console.log("Running", response.type);
            if (response.type === "VM" || response.type === "VMC") {
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status", labIntervalCheck.current);
                socketRef.current.send(
                  JSON.stringify({ cmd: "VMStatus", id: response.id })
                );
              }, 20000);
            } else {
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status 156", response.id)
                socketRef.current.send(
                  JSON.stringify({ cmd: "getStatus", id: response.id })
                );
              }, 5000);
            }
          }
        } else if (response.cmd === "CloseConn") {
          setIsConnected(false);
          socketRef.current.close();
          socketRef.current = null;
          newSocket = null;
          labIntervalCheck.current = null;
          labStatus_ref.current = false;

          console.log("WebSocket cleaned...");
        } else if (response.cmd === "Terminating") {
          setLabRunning(false);
          setLabId(null);
          // console.log("Terminating", response.id, labId);
          if (!labIntervalCheck.current) {
            // console.log("Running", response.type);
            //Condicao quando o servidor é desligado mas ficou preso um lab rodando em estado terminating
            socketRef.current.send(
              JSON.stringify({ cmd: "stop", id: response.id })
            );
            if (response.type === "VM" || response.type === "VMC") {
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status", labIntervalCheck.current);
                socketRef.current.send(
                  JSON.stringify({ cmd: "VMStatus", id: response.id })
                );
              }, 20000);
            } else {
              labIntervalCheck.current = setInterval(() => {
                // console.log("Running Status 191", response.id)
                socketRef.current.send(
                  JSON.stringify({ cmd: "getStatus", id: response.id })
                );
              }, 5000);
            }
          }
        } else if (response.cmd === "Error") {

          labStatus_ref.current = false;
          setLabRunning(false);
          setLabId(null);
        }
      };
    }
  };

  const checkWebSocketStatus = () => {
    if (
      !socketRef.current ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      console.log("WebSocket not connected, attempting to connect...");
      connectWebSocket();
    }
  };

  useEffect(() => {
    connectWebSocket();

    const intervalId = setInterval(checkWebSocketStatus, 5000);

    // Cleanup function to clear the interval and close the WebSocket when the component unmounts
    return () => {
      clearInterval(intervalId);
      socketRef.current.close();
      setIsConnected(false);
    };
  }, []);

  const ret = [
    isConnected,
    rxMessage,
    socketRef.current?.send.bind(socketRef.current),
    labRunning,
    labId,
  ];

  return (
    <WebsocketContext.Provider value={ret}>
      {children}
    </WebsocketContext.Provider>
  );
};
